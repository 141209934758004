<template>
    <div class="floatingAdv" ref="floatingAdv"
         @touchstart.stop="handleTouchStart"
         @touchmove.prevent.stop="handleTouchMove($event)"
         @touchend.stop="handleTouchEnd"
         :style="{
                left: left + 'px',
                top: top + 'px',
                width: '60px',
                height: '60px',
         }"
         v-if="advInfo && showAdv"
         @click="goAdv(advInfo)"
    >
        <ImgDecypt class="cover" :key="advInfo['cover']" :src="advInfo['cover']">
         <div class="close" @click.stop="showAdv = false">
          <SvgIcon iconClass="closeIcon" class="closeIcon"></SvgIcon>
         </div>
        </ImgDecypt>
    </div>
</template>

<script>

import {AdType, getAdItem, jumpAdv} from "@/utils/getConfig";
import ImgDecypt from "@/components/ImgDecypt";
import SvgIcon from '@/components/SvgIcon'
export default {
    name: "floatingAdv",
    components:{
        ImgDecypt,
     SvgIcon
    },
    props:{

    },data(){
        return {
            left: 0, // 距离左边距离
            top: 0, // 距离抬头距离
            clientW: document.documentElement.clientWidth, //视口宽
            clientH: document.documentElement.clientHeight, //视口高
            advInfo: null,
            showAdv: true
        }
    },computed:{

    },mounted() {
        // 初始化定义距离四周距离
        this.left = this.clientW - 65 - 12;
        this.top = this.clientH - 80  - 98;
        let advList = getAdItem(AdType.FLOATING_ADV);
        if(advList.length > 0){
            this.advInfo = advList[0];
        }
    },methods:{
        handleTouchStart(){
            /**
             * 开始移动方法
             */
        },handleTouchMove(e){
            /**
             * 移动中的方法
             */
            const clientX = e.targetTouches[0].clientX; //手指相对视口的x
            const clientY = e.targetTouches[0].clientY; //手指相对视口的y
            const isInScreen =
                clientX <= this.clientW &&
                clientX >= 0 &&
                clientY <= this.clientH &&
                clientY >= 0;
            if (e.targetTouches.length === 1) {
                if (isInScreen) {
                    this.left = clientX - 100 / 2;
                    this.top = clientY - 100 / 2;
                }
            }

        },handleTouchEnd(e){
            /**
             * 移动结束
             */

            if (this.left < this.clientW / 2) {
                this.left = 12; //不让贴边 所以设置12没设置0
                this.handleIconY();
            } else {
                this.left = this.clientW - 60 - 12; //距边30px
                this.handleIconY();
            }

            this.$refs.floatingAdv.style.transition = "all .1s";

        },handleIconY(){
            if (this.top < 0) {
                this.top = 12; //不上帖上边所以设置为12 没设置0
            } else if (this.top + 100 > this.clientH) {
                this.top = this.clientH - 100 - 12; //距边30px
            }
        },goAdv(item) {
            jumpAdv(item);
        }
    }
}
</script>

<style lang="scss" scoped>

    .floatingAdv{
        position: fixed;
        right: 0;
        bottom: 0;
        color: #fff;
        //background: red;
        z-index: 20;
     .cover {
      width: 60px;
      height: 60px;
      /deep/ .van-image__img {
       border-radius: 10px;
      }
      .close {
       position: absolute;
       right: -5px;
       top: 0;
       z-index: 22;
       width: 13px;
       height: 13px;
       background: #fff;
       border-radius: 33px;
       display: flex;
       align-items: center;
       justify-content: center;
       .closeIcon {
        width: 12px;
        height: 12px;
       }
      }
     }
    }

</style>
